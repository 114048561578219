import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { DAY_FILTERS, DAY_GROUPS } from '../config/constants';
import { COLORS_BY_DAY } from '../config/theme';

const ListFiltersContainer = styled.div`
    display: flex;
    place-content: center;
    padding: 10px;
    flex-wrap: wrap;
`;

const FilterButtonContainer = styled.div`
    display: flex;
`;

const FilterButton = styled.div`
    cursor: pointer;
    color: gray;
    padding: 0 5px;
    ${({ selected }) => selected && 'font-weight: bold;'}
    ${({ selected }) => selected && 'text-decoration: underline;'}
    ${({ selected, $day }) => selected && 'color: ' + COLORS_BY_DAY[$day] + ';'}
`;

export function ListFilters({ eventsByDay, handleClickDay, selectedDay }) {
    const numberOfWeekendEvents = useMemo(
        () =>
            (eventsByDay.Friday?.length || 0) +
            (eventsByDay.Saturday?.length || 0) +
            (eventsByDay.Sunday?.length || 0),
        [eventsByDay]
    );

    const getEventsByDay = useCallback(
        (day) => {
            if (day === DAY_GROUPS.Weekend) return numberOfWeekendEvents;
            return eventsByDay[day].length;
        },
        [numberOfWeekendEvents, eventsByDay]
    );

    return (
        <ListFiltersContainer>
            <span>Filter by:</span>
            {DAY_FILTERS.map((day) => (
                <FilterButtonContainer key={day}>
                    <FilterButton
                        onClick={() => handleClickDay(day)}
                        selected={selectedDay === day}
                        $day={day}
                    >
                        {`${day} (${getEventsByDay(day)})`}
                    </FilterButton>
                    {day !== DAY_GROUPS.Weekend && <span>|</span>}
                </FilterButtonContainer>
            ))}
        </ListFiltersContainer>
    );
}
